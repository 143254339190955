/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Nav, Navbar } from 'react-bootstrap';

function Header({ location }) {
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  // close navbar on every location change
  useEffect(() => {
    setNavbarExpanded(false);
  }, [location.pathname]);


  return (
    <Navbar
      className="p-lg-0 border-bottom border-tertiary"
      sticky="top"
      collapseOnSelect
      expand="lg"
      expanded={navbarExpanded}
      onToggle={() => setNavbarExpanded(!navbarExpanded)}
    >
      <div className="container-fluid d-lg-flex align-items-lg-stretch">
        <div className="py-lg-3">
          <Link to="/" className="text-primary">
            <img src="/logos/emvelia-logo-150.png" alt="Emvelia Technologies" />
            <div style={{ marginTop: -10 }} className="font-size-sm text-right">Taking You Forward</div>
          </Link>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="d-lg-flex align-items-lg-stretch">
          <Nav className="d-flex align-items-stretch ml-lg-auto">
            <Link
              to="/who-we-are"
              className={classNames(
                'px-2 py-2 py-lg-0 flex-fill d-lg-flex align-items-lg-center text-decoration-none',
                { selected: location.pathname.includes('/who-we-are') },
              )}
            >
              <h6 className="font-weight-lighter text-center">
                WHO WE ARE
              </h6>
            </Link>
            <Link
              to="/what-we-do"
              className={classNames(
                'px-2 py-2 py-lg-0 flex-fill d-lg-flex align-items-lg-center text-decoration-none',
                { selected: location.pathname.includes('/what-we-do') },
              )}
            >
              <h6 className="font-weight-lighter text-center">WHAT WE DO</h6>
            </Link>
            <Link
              to="/impact"
              className={classNames(
                'px-2 py-2 py-lg-0 flex-fill d-lg-flex align-items-lg-center text-decoration-none',
                { selected: location.pathname.includes('/impact') },
              )}
            >
              <h6 className="font-weight-lighter text-center">
                HOW WE IMPACT
              </h6>
            </Link>
            <Link
              to="/press-media"
              className={classNames(
                'px-2 py-2 py-lg-0 flex-fill d-lg-flex align-items-lg-center text-decoration-none',
                { selected: location.pathname.includes('/press-media') },
              )}
            >
              <h6 className="font-weight-lighter text-center">PRESS & MEDIA</h6>
            </Link>
            <Link
              to="/careers"
              className={classNames(
                'px-2 py-2 py-lg-0 flex-fill d-lg-flex align-items-lg-center text-decoration-none',
                { selected: location.pathname.includes('/careers') },
              )}
            >
              <h6 className="font-weight-lighter text-center">CAREERS</h6>
            </Link>
            <Link
              to="/investors"
              className={classNames(
                'px-2 py-2 py-lg-0 flex-fill d-lg-flex align-items-lg-center text-decoration-none',
                { selected: location.pathname.includes('/investors') },
              )}
            >
              <h6 className="font-weight-lighter text-center">INVESTORS</h6>
            </Link>
            <Link
              to="/contact-us"
              className={classNames(
                'px-2 py-2 py-lg-0 flex-fill d-lg-flex align-items-lg-center text-decoration-none',
                { selected: location.pathname.includes('/contact-us') },
              )}
            >
              <h6 className="font-weight-lighter text-center">CONTACT US</h6>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;
