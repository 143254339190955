import React from 'react';
import moment from 'moment';


function Footer() {
  return (
    <div className="wrapper footer">
      {/* new implementation */}
      <div className="container-fluid py-3">
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 justify-content-center justify-content-md-start align-items-center d-flex">
            <i className="ai-shuttle" />
            <div className="px-2">
              <div className="display-4 font-weight-bold">Contact us</div>
              <p>
                <a
                  href="mailto:contact@emvelia.com"
                  target="_blank"
                  className="link-secondary"
                >contact@emvelia.com
                </a>
              </p>
            </div>
          </div>
          <div className="col-8 col-md-4 d-flex justify-content-center justify-content-md-center align-items-center text-center text-md-left">
            <div className="d-flex align-items-center">
              <span className="ml-auto"><i className="ai-location" /></span>
              <div className="px-2 pt-2">
                <div className="display-4 font-weight-bold">Offices</div>
                <p>London, United Kingdom | Athens, Greece</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
            <span>
              <a className="btn btn-primary d-flex align-items-center" href="/assets/Emvelia Technologies - Brochure.pdf" download="Emvelia Technologies - Brochure.pdf">
                <i className="ai-pdf">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                  <span className="path5" />
                  <span className="path6" />
                  <span className="path7" />
                  <span className="path8" />
                </i>
                <span className="ml-1">Download our brochure</span>
              </a>
            </span>
          </div>
        </div>
      </div>
      {/* the footer links */}
      <div className="wrapper bg-secondary">
        <div className="container-fluid">
          <div className="row py-3 text-center justify-content-center links">
            <div className="col-12 col-md-10 py-2">
              <span className="text-primary px-1">Discover </span>
              <span><a href="https://github.com/artlimes" target="_blank">GitHub</a></span>
              <span><a href="https://www.npmjs.com/~artlimes" target="_blank"><span className="px-1">•</span>Npm</a></span>
              <span><a href="https://atmospherejs.com/artlimes/" target="_blank"><span className="px-1">•</span>Atmosphere</a></span>
              <span><a href="https://docs.reactioncommerce.com/docs/3.0.0/community-showcase" target="_blank"> <span className="px-1">•</span>Reaction Commerce</a></span>
            </div>

            <div className="col-12 col-md-8 mx-auto small copy-right">
              Copyright © {moment().format('YYYY')} Emvelia Technologies Ltd. Emvelia Technologies is registered in England and Wales with company number 13198499 and registered office in London, United Kingdom.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
