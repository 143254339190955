/**
 * Layout component is wrapped around every page, by using gatsby-plugin-layout
 */

import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'components/footer/footer';
import Header from 'components/navbar/navbar';

import 'scss/main.scss';
import { Router } from '@reach/router';
import CareersHeader from '../templates/careers/careersHeader';


const Layout = ({ children, location, navigate }) => (
  <>
    <Header location={location} navigate={navigate} />
    <Router>

      <CareersHeader path="careers/*" />
    </Router>
    <main>{children}</main>

    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
