// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-senior-full-stack-developer-js": () => import("./../../../src/pages/careers/senior-full-stack-developer.js" /* webpackChunkName: "component---src-pages-careers-senior-full-stack-developer-js" */),
  "component---src-pages-careers-technical-project-manager-js": () => import("./../../../src/pages/careers/technical-project-manager.js" /* webpackChunkName: "component---src-pages-careers-technical-project-manager-js" */),
  "component---src-pages-case-studies-information-communication-assistance-center-kaeb-js": () => import("./../../../src/pages/case-studies/information-communication-assistance-center-kaeb.js" /* webpackChunkName: "component---src-pages-case-studies-information-communication-assistance-center-kaeb-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-dynamic-js": () => import("./../../../src/pages/index_dynamic.js" /* webpackChunkName: "component---src-pages-index-dynamic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-js": () => import("./../../../src/pages/investors.js" /* webpackChunkName: "component---src-pages-investors-js" */),
  "component---src-pages-press-media-js": () => import("./../../../src/pages/press-media.js" /* webpackChunkName: "component---src-pages-press-media-js" */),
  "component---src-pages-what-we-do-drone-in-a-box-js": () => import("./../../../src/pages/what-we-do/drone-in-a-box.js" /* webpackChunkName: "component---src-pages-what-we-do-drone-in-a-box-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

