import React from 'react';


function CareersHeader({ children }) {
  return (
    <>
      <img className="img-fluid d-md-block d-none mx-auto" src="/assets/careers-desktop.jpg" alt=" " />
      <img className="img-fluid d-md-none d-block mx-auto" src="/assets/careers-mobile.jpg" alt=" " />
      {children}
    </>
  );
}

export default CareersHeader;
